import React from 'react';
import {
  HStack,
  LinearGradient,
  Link,
  ScreenContainer,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { H3 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Image, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoCollectionApi from '../apis/XanoCollectionApi.js';
import EventDetailsModalBlock from '../components/EventDetailsModalBlock';
import LoadingBlock from '../components/LoadingBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import assessAccess from '../global-functions/assessAccess';
import cutTextByWidth from '../global-functions/cutTextByWidth';
import deviceType from '../global-functions/deviceType';
import isNKPProp from '../global-functions/isNKPProp';
import removeGlobalScroll from '../global-functions/removeGlobalScroll';
import resetAccess from '../global-functions/resetAccess';
import screenNameGen from '../global-functions/screenNameGen';
import showNKPProp from '../global-functions/showNKPProp';
import transformEuroM from '../global-functions/transformEuroM';
import transformNumber from '../global-functions/transformNumber';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { event_id: 1 };

const EventDetailsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [eventId, setEventId] = React.useState(
    props.route?.params?.event_id ?? defaultProps.event_id
  );
  const [screenCode, setScreenCode] = React.useState('');
  const getListNameFormArray = array => {
    return array.map(item => item?.name).join(', ');
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      /* hidden 'Set Variable' action */
      removeGlobalScroll(Platform.OS === 'web');
      setGlobalVariableValue({
        key: 'currentScreen',
        value: 'Event Details',
      });
      /* hidden 'Set Variable' action */
      /* hidden 'Set Variable' action */
      setGlobalVariableValue({
        key: 'pageName',
        value: 'Event Details',
      });
      setGlobalVariableValue({
        key: 'subPage',
        value: true,
      });
      if (assessAccess(Variables, setGlobalVariableValue) === true) {
        return;
      }
      if (navigation.canGoBack()) {
        navigation.popToTop();
      }
      navigation.replace('LogInScreen');
      /* hidden 'Run a Custom Function' action */
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasLeftSafeArea={false}
      hasRightSafeArea={false}
      hasTopSafeArea={true}
    >
      <EventDetailsModalBlock
        asModal={false}
        setViewingEventId={viewingEventId => setEventId(viewingEventId)}
        viewingEventId={eventId}
      />
    </ScreenContainer>
  );
};

export default withTheme(EventDetailsScreen);
