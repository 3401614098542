import React from 'react';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Platform } from 'react-native';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as WebAuthSession from '../custom-files/WebAuthSession';
import palettes from '../themes/palettes';
import useWindowDimensions from '../utils/useWindowDimensions';

const RedirectScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const replaceUrl = () => {
    // const { makeRedirectUri } = WebAuthSession

    let [_, queryString] = window.location.href.split('/ios/');
    if (queryString)
      queryString = queryString.replace(
        'AllEventsScreen/',
        'EventDetailsScreen/'
      );
    let baseUrl = 'mainsights-app://';
    const newUrl = queryString ? `${baseUrl}${queryString}` : baseUrl;
    console.log('new Url ', newUrl);

    window.location.replace(newUrl);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (Platform.OS === 'web') {
        replaceUrl();
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return <ScreenContainer hasSafeArea={false} scrollable={false} />;
};

export default withTheme(RedirectScreen);
