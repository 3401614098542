/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

function renderLinkingPrefix() {
  try {
    return Linking.createURL('/');
  } catch (e) {
    return 'draftbit://';
  }
}

const prefix = renderLinkingPrefix();

const linking = {
  enabled: true,
  prefixes: [prefix],
  config: {
    screens: {
      MandAInsightsAppScreen: '/nkp',
      AdvisorDetailsScreen: '/nkp/advisor_details/:advisor_id?',

      AdvisorsScreen: '/nkp/advisors',

      AllEventsScreen: '/nkp/all_events/:event_id?',

      CFSDetailsScreen: '/nkp/cfs_details/:cfs_id?',

      CFSScreen: '/nkp/cfs',

      EventDetailsScreen: '/nkp/event_details/:event_id?',

      ForgotPasswordScreen: '/nkp/forgot_password',

      LogInScreen: '/nkp/login',

      MultiplesScreen: '/nkp/multiple',

      NewsletterDetailsScreen:  '/nkp/newsletter_details/:news_id?',

      NewslettersScreen: '/nkp/newsletters',

      PEPFDetailsScreen: '/nkp/pepf_details/:pepf_id?',

      PEPFScreen: '/nkp/pepf',

      PeerGroupDetailsScreen:  '/nkp/peer_group_details/:peer_group_id?',

      PeerGroupsScreen: '/nkp/peer_group',

      PrivacyPolicyScreen: '/nkp/privacy_policy',

      ReportsScreen: '/nkp/report',

      StockDetailsScreen:  '/nkp/stock_details/:stock_id?',

      StockSearchScreen: '/nkp/stock_search',

      TermsAndConditionsScreen: '/nkp/terms_conditions', 
      MyAccountScreen: '/nkp/my_account',
      RedirectScreen: '/nkp/ios/*'
    },
  },
};

export default linking;
