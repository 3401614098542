import { View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
export const WaterMark = ({
  children,
  markerHeight,
  parentHeight,
  markerWidth,
  numberOfItems,
  theme,
}) => {
  const diagonal = Math.sqrt(
    markerHeight * markerHeight + markerWidth * markerWidth
  );

  let effHeight = markerHeight * 0.5736 + markerWidth * 0.8192;
  let length = Math.floor(parentHeight / effHeight) || 1;
  // console.log({ parentHeight, markerHeight, markerWidth, length, effHeight })

  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'space-evenly',
        height: '100%',
        gap: 16,
        width: '100%',
        padding: 12,
        overflow: 'hidden',
        flex: 1,
      }}
    >
      {Array.from({ length }).map((_, index) => children)}
    </View>
  );
};
