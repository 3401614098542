const formatDateForQuery = time => {
  // Assuming 'time' is a JavaScript Date object. If not, you need to parse it as a Date object first.
  console.log(time, Object.prototype.toString.call(time) === '[object Date]');
  if (Object.prototype.toString.call(time) === '[object Date]') {
    const formattedDate = `${('0' + (time.getMonth() + 1)).slice(-2)}/${(
      '0' + time.getDate()
    ).slice(-2)}/${time.getFullYear()}`;
    return formattedDate;
  } else {
    return '';
  }
};

export default formatDateForQuery;
